import GeneralSetting from "./../../models/general/generalSettings/GeneralSetting";
import { setGeneralSetting, toggleSidebarStatus } from "./../store-types";

export default {
  state: {
    generalSetting: null,
    sidebarIsClosed: false,
  },
  getters: {
    generalSetting: (state) => state.generalSetting || new GeneralSetting(),
    sidebarIsClosed: (state) => state.sidebarIsClosed,
  },
  mutations: {
    [setGeneralSetting]: (state, generalSetting) => {
      // state.generalSetting = new GeneralSetting();
      state.generalSetting = generalSetting;
    },
    [toggleSidebarStatus]: (state) => {
      state.sidebarIsClosed = !state.sidebarIsClosed;
    },
  },
  actions: {
    setGeneralSetting(context, generalSetting) {
      context.commit(setGeneralSetting, generalSetting);
    },
    toggleSidebarStatus(context) {
      context.commit(toggleSidebarStatus);
    },
  },
  modules: {},
};
