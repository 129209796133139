import GeneralSettingModel from "./GeneralSettingModel";

export default class GeneralSetting {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.banks = new GeneralSettingModel();
    this.userQualifications = new GeneralSettingModel();
    this.knownMethods = new GeneralSettingModel();
    this.bloodTypes = new GeneralSettingModel();
    this.certificates = new GeneralSettingModel();
    this.cities = new GeneralSettingModel();
    this.countries = new GeneralSettingModel();
    this.districts = new GeneralSettingModel();
    this.governorates = new GeneralSettingModel();
    this.insuranceCompanies = new GeneralSettingModel();
    this.militaryStatusTypes = new GeneralSettingModel();
    this.qualifications = new GeneralSettingModel();
    this.religions = new GeneralSettingModel();
    this.installmentBatchTypes = new GeneralSettingModel();
    this.socialStatuses = new GeneralSettingModel();
    this.users = new GeneralSettingModel();
    this.masterAdmins = new GeneralSettingModel();
    this.employees = new GeneralSettingModel();
    this.students = new GeneralSettingModel();
    this.adminsEgypt = new GeneralSettingModel();

    this.adminsEgyptFamily = new GeneralSettingModel();
    this.masterAdminFamily = new GeneralSettingModel();
    this.employeeFamily = new GeneralSettingModel();
    this.studentFamily = new GeneralSettingModel();

    this.adminsEgyptValidSetting = new GeneralSettingModel();
    this.masterAdminValidSetting = new GeneralSettingModel();
    this.employeeValidSetting = new GeneralSettingModel();
    this.studentValidSetting = new GeneralSettingModel();

    this.adminsEgyptLoginCode = new GeneralSettingModel();
    this.masterAdminLoginCode = new GeneralSettingModel();
    this.employeeLoginCode = new GeneralSettingModel();
    this.studentLoginCode = new GeneralSettingModel();

    this.adminsEgyptEstablishmentsRole = new GeneralSettingModel();
    this.masterAdminEstablishmentsRole = new GeneralSettingModel();
    this.employeeEstablishmentsRole = new GeneralSettingModel();
    this.studentEstablishmentsRole = new GeneralSettingModel();

    this.adminsEgyptQualification = new GeneralSettingModel();
    this.masterAdminQualification = new GeneralSettingModel();
    this.employeeQualification = new GeneralSettingModel();
    this.studentQualification = new GeneralSettingModel();

    this.adminsEgyptScientificDegree = new GeneralSettingModel();
    this.masterAdminScientificDegree = new GeneralSettingModel();
    this.employeeScientificDegree = new GeneralSettingModel();
    this.studentScientificDegree = new GeneralSettingModel();

    this.adminsEgyptPersonalCard = new GeneralSettingModel();
    this.masterAdminPersonalCard = new GeneralSettingModel();
    this.employeePersonalCard = new GeneralSettingModel();
    this.studentPersonalCard = new GeneralSettingModel();

    this.adminsEgyptTrainingCourse = new GeneralSettingModel();
    this.masterAdminTrainingCourse = new GeneralSettingModel();
    this.employeeTrainingCourse = new GeneralSettingModel();
    this.studentTrainingCourse = new GeneralSettingModel();

    this.adminsEgyptExperience = new GeneralSettingModel();
    this.masterAdminExperience = new GeneralSettingModel();
    this.employeeExperience = new GeneralSettingModel();
    this.studentExperience = new GeneralSettingModel();

    this.geographicalDistributions = new GeneralSettingModel();

    this.educationalPeriods = new GeneralSettingModel();
    this.educationalCategories = new GeneralSettingModel();
    this.educationalJoiningApplications = new GeneralSettingModel();
    this.joiningApplicationSubscriptions = new GeneralSettingModel();
    this.educationalGroups = new GeneralSettingModel();
    this.educationalGroupAttendances = new GeneralSettingModel();
    this.educationalScheduleTimes = new GeneralSettingModel();
    this.simpleExamModels = new GeneralSettingModel();
    this.groupScheduleExams = new GeneralSettingModel();
    this.studentScheduleExamTimes = new GeneralSettingModel();

    this.systemComponentPlace = new GeneralSettingModel();
    this.systemComponentEducationalCategory = new GeneralSettingModel();
    this.systemComponentGeographicalDistribution = new GeneralSettingModel();
    this.systemComponentJobGrade = new GeneralSettingModel();
    this.systemComponentStoreItem = new GeneralSettingModel();

    this.systemComponentsHierarchyPlace = new GeneralSettingModel();
    this.systemComponentsHierarchyEducationalCategory =
      new GeneralSettingModel();
    this.systemComponentsHierarchyGeographicalDistribution =
      new GeneralSettingModel();
    this.systemComponentsHierarchyJobGrade = new GeneralSettingModel();
    this.systemComponentsHierarchyStoreItem = new GeneralSettingModel();

    this.places = new GeneralSettingModel();

    this.storageSpacesAdminsEgypt = new GeneralSettingModel();
    this.storageSpacesMasterAdmin = new GeneralSettingModel();
    this.storageSpacesEmployees = new GeneralSettingModel();
    this.storageSpacesStudents = new GeneralSettingModel();
    this.storageSpacesPlaces = new GeneralSettingModel();
    this.storageSpacesEducationalGroup = new GeneralSettingModel();

    this.storageSpaceMediasAdminsEgypt = new GeneralSettingModel();
    this.storageSpaceMediasMasterAdmin = new GeneralSettingModel();
    this.storageSpaceMediasEmployees = new GeneralSettingModel();
    this.storageSpaceMediasStudents = new GeneralSettingModel();
    this.storageSpaceMediasPlaces = new GeneralSettingModel();
    this.storageSpaceMediasEducationalGroup = new GeneralSettingModel();

    this.jobGrades = new GeneralSettingModel();
    this.colors = new GeneralSettingModel();
    this.priorityTypes = new GeneralSettingModel();
    this.complaintTypes = new GeneralSettingModel();
    this.newsCategories = new GeneralSettingModel();
    this.complaintSectors = new GeneralSettingModel();
    this.reminderPlans = new GeneralSettingModel();
    this.expensesTypes = new GeneralSettingModel();
    this.revenuesTypes = new GeneralSettingModel();
    this.paymentMethods = new GeneralSettingModel();
    this.gradingPlans = new GeneralSettingModel();
    this.priceLists = new GeneralSettingModel();
    this.relativeRelationTypes = new GeneralSettingModel();

    this.accounts = new GeneralSettingModel();
    this.accountTransactions = new GeneralSettingModel();
    this.countedServiceTransactions = new GeneralSettingModel();
    this.expenses = new GeneralSettingModel();
    this.revenues = new GeneralSettingModel();
    this.serviceTypes = new GeneralSettingModel();
    this.countedServiceTypes = new GeneralSettingModel();
    this.services = new GeneralSettingModel();
    this.countedServices = new GeneralSettingModel();
    this.newss = new GeneralSettingModel();
    this.complaints = new GeneralSettingModel();
    this.complaintFollowUps = new GeneralSettingModel();
    this.educationalRatings = new GeneralSettingModel();
    this.debts = new GeneralSettingModel();
    this.installments = new GeneralSettingModel();
    this.installmentPayments = new GeneralSettingModel();
    this.serviceEntitlements = new GeneralSettingModel();
    this.interests = new GeneralSettingModel();
    this.interestFollowUps = new GeneralSettingModel();
    this.userInterests = new GeneralSettingModel();
    this.notifications = new GeneralSettingModel();
    this.reminders = new GeneralSettingModel();
    this.educationalGroupStudents = new GeneralSettingModel();
    this.educationalGroupEmployees = new GeneralSettingModel();
    this.educationalGroupPlaces = new GeneralSettingModel();
    this.educationalGroupTransfers = new GeneralSettingModel();
    this.classificationDegrees = new GeneralSettingModel();
    this.generalJobs = new GeneralSettingModel();
    this.personalCardTypes = new GeneralSettingModel();
    this.scientificDegrees = new GeneralSettingModel();
    // this.establishmentRoles = new GeneralSettingModel();
  }
  fillData(data) {
    if (data) {
      // this.establishmentRoles.fillData(data.establishmentRoles);
      this.banks.fillData(data.banks);
      this.userQualifications.fillData(data.userQualifications);
      this.knownMethods.fillData(data.knownMethods);
      this.bloodTypes.fillData(data.bloodTypes);
      this.certificates.fillData(data.certificates);
      this.cities.fillData(data.cities);
      this.countries.fillData(data.countries);
      this.districts.fillData(data.districts);
      this.governorates.fillData(data.governorates);
      this.insuranceCompanies.fillData(data.insuranceCompanies);
      this.militaryStatusTypes.fillData(data.militaryStatusTypes);
      this.qualifications.fillData(data.qualifications);
      this.religions.fillData(data.religions);
      this.installmentBatchTypes.fillData(data.installmentBatchTypes);
      this.socialStatuses.fillData(data.socialStatuses);
      // this.userValidSettings.fillData(data.userValidSettings);
      this.users.fillData(data.users);
      this.masterAdmins.fillData(data.masterAdmins);
      this.employees.fillData(data.employees);
      this.students.fillData(data.students);
      this.adminsEgypt.fillData(data.adminsEgypt);

      this.adminsEgyptFamily.fillData(data.adminsEgyptFamily);
      this.masterAdminFamily.fillData(data.masterAdminFamily);
      this.employeeFamily.fillData(data.employeeFamily);
      this.studentFamily.fillData(data.studentFamily);

      this.adminsEgyptValidSetting.fillData(data.adminsEgyptValidSetting);
      this.masterAdminValidSetting.fillData(data.masterAdminValidSetting);
      this.employeeValidSetting.fillData(data.employeeValidSetting);
      this.studentValidSetting.fillData(data.studentValidSetting);

      this.adminsEgyptLoginCode.fillData(data.adminsEgyptLoginCode);
      this.masterAdminLoginCode.fillData(data.masterAdminLoginCode);
      this.employeeLoginCode.fillData(data.employeeLoginCode);
      this.studentLoginCode.fillData(data.studentLoginCode);

      this.adminsEgyptEstablishmentsRole.fillData(
        data.adminsEgyptEstablishmentsRole
      );
      this.masterAdminEstablishmentsRole.fillData(
        data.masterAdminEstablishmentsRole
      );
      this.employeeEstablishmentsRole.fillData(data.employeeEstablishmentsRole);
      this.studentEstablishmentsRole.fillData(data.studentEstablishmentsRole);

      this.adminsEgyptQualification.fillData(data.adminsEgyptQualification);
      this.masterAdminQualification.fillData(data.masterAdminQualification);
      this.employeeQualification.fillData(data.employeeQualification);
      this.studentQualification.fillData(data.studentQualification);

      this.adminsEgyptScientificDegree.fillData(
        data.adminsEgyptScientificDegree
      );
      this.masterAdminScientificDegree.fillData(
        data.masterAdminScientificDegree
      );
      this.employeeScientificDegree.fillData(data.employeeScientificDegree);
      this.studentScientificDegree.fillData(data.studentScientificDegree);

      this.adminsEgyptPersonalCard.fillData(data.adminsEgyptPersonalCard);
      this.masterAdminPersonalCard.fillData(data.masterAdminPersonalCard);
      this.employeePersonalCard.fillData(data.employeePersonalCard);
      this.studentPersonalCard.fillData(data.studentPersonalCard);

      this.adminsEgyptTrainingCourse.fillData(data.adminsEgyptTrainingCourse);
      this.masterAdminTrainingCourse.fillData(data.masterAdminTrainingCourse);
      this.employeeTrainingCourse.fillData(data.employeeTrainingCourse);
      this.studentTrainingCourse.fillData(data.studentTrainingCourse);

      this.adminsEgyptExperience.fillData(data.adminsEgyptExperience);
      this.masterAdminExperience.fillData(data.masterAdminExperience);
      this.employeeExperience.fillData(data.employeeExperience);
      this.studentExperience.fillData(data.studentExperience);

      this.geographicalDistributions.fillData(data.geographicalDistributions);

      this.educationalPeriods.fillData(data.educationalPeriods);
      this.educationalCategories.fillData(data.educationalCategories);
      this.educationalJoiningApplications.fillData(
        data.educationalJoiningApplications
      );
      this.joiningApplicationSubscriptions.fillData(
        data.joiningApplicationSubscriptions
      );
      this.educationalGroups.fillData(data.educationalGroups);
      this.educationalGroupAttendances.fillData(
        data.educationalGroupAttendances
      );
      this.educationalScheduleTimes.fillData(data.educationalScheduleTimes);
      this.simpleExamModels.fillData(data.simpleExamModels);
      this.groupScheduleExams.fillData(data.groupScheduleExams);
      this.studentScheduleExamTimes.fillData(data.studentScheduleExamTimes);

      this.systemComponentPlace.fillData(data.systemComponentPlace);
      this.systemComponentEducationalCategory.fillData(
        data.systemComponentEducationalCategory
      );
      this.systemComponentGeographicalDistribution.fillData(
        data.systemComponentGeographicalDistribution
      );
      this.systemComponentJobGrade.fillData(data.systemComponentJobGrade);
      this.systemComponentStoreItem.fillData(data.systemComponentStoreItem);

      this.systemComponentsHierarchyPlace.fillData(
        data.systemComponentsHierarchyPlace
      );
      this.systemComponentsHierarchyEducationalCategory.fillData(
        data.systemComponentsHierarchyEducationalCategory
      );
      this.systemComponentsHierarchyGeographicalDistribution.fillData(
        data.systemComponentsHierarchyGeographicalDistribution
      );
      this.systemComponentsHierarchyJobGrade.fillData(
        data.systemComponentsHierarchyJobGrade
      );
      this.systemComponentsHierarchyStoreItem.fillData(
        data.systemComponentsHierarchyStoreItem
      );

      this.places.fillData(data.places);

      this.storageSpacesAdminsEgypt.fillData(data.storageSpacesAdminsEgypt);
      this.storageSpacesMasterAdmin.fillData(data.storageSpacesMasterAdmin);
      this.storageSpacesEmployees.fillData(data.storageSpacesEmployees);
      this.storageSpacesStudents.fillData(data.storageSpacesStudents);
      this.storageSpacesPlaces.fillData(data.storageSpacesPlaces);
      this.storageSpacesEducationalGroup.fillData(
        data.storageSpacesEducationalGroup
      );

      this.storageSpaceMediasAdminsEgypt.fillData(
        data.storageSpaceMediasAdminsEgypt
      );
      this.storageSpaceMediasMasterAdmin.fillData(
        data.storageSpaceMediasMasterAdmin
      );
      this.storageSpaceMediasEmployees.fillData(
        data.storageSpaceMediasEmployees
      );
      this.storageSpaceMediasStudents.fillData(data.storageSpaceMediasStudents);
      this.storageSpaceMediasPlaces.fillData(data.storageSpaceMediasPlaces);
      this.storageSpaceMediasEducationalGroup.fillData(
        data.storageSpaceMediasEducationalGroup
      );

      this.jobGrades.fillData(data.jobGrades);
      this.colors.fillData(data.colors);
      this.priorityTypes.fillData(data.priorityTypes);
      this.complaintTypes.fillData(data.complaintTypes);
      this.newsCategories.fillData(data.newsCategories);
      this.complaintSectors.fillData(data.complaintSectors);
      this.reminderPlans.fillData(data.reminderPlans);
      this.expensesTypes.fillData(data.expensesTypes);
      this.revenuesTypes.fillData(data.revenuesTypes);
      this.paymentMethods.fillData(data.paymentMethods);
      this.gradingPlans.fillData(data.gradingPlans);
      this.priceLists.fillData(data.priceLists);
      this.relativeRelationTypes.fillData(data.relativeRelationTypes);

      this.accounts.fillData(data.accounts);
      this.accountTransactions.fillData(data.accountTransactions);
      this.countedServiceTransactions.fillData(data.countedServiceTransactions);
      this.expenses.fillData(data.expenses);
      this.revenues.fillData(data.revenues);
      this.serviceTypes.fillData(data.serviceTypes);
      this.countedServiceTypes.fillData(data.countedServiceTypes);
      this.services.fillData(data.services);
      this.countedServices.fillData(data.countedServices);
      this.newss.fillData(data.newss);
      this.complaints.fillData(data.complaints);
      this.complaints.fillData(data.complaints);
      this.complaintFollowUps.fillData(data.complaintFollowUps);
      this.educationalRatings.fillData(data.educationalRatings);
      this.debts.fillData(data.debts);
      this.installments.fillData(data.installments);
      this.installmentPayments.fillData(data.installmentPayments);
      this.serviceEntitlements.fillData(data.serviceEntitlements);
      this.interests.fillData(data.interests);
      this.interestFollowUps.fillData(data.interestFollowUps);
      this.userInterests.fillData(data.userInterests);
      this.notifications.fillData(data.notifications);
      this.reminders.fillData(data.reminders);
      this.educationalGroupStudents.fillData(data.educationalGroupStudents);
      this.educationalGroupEmployees.fillData(data.educationalGroupEmployees);
      this.educationalGroupPlaces.fillData(data.educationalGroupPlaces);
      this.educationalGroupTransfers.fillData(data.educationalGroupTransfers);
      this.classificationDegrees.fillData(data.classificationDegrees);
      this.generalJobs.fillData(data.generalJobs);
      this.personalCardTypes.fillData(data.personalCardTypes);
      this.scientificDegrees.fillData(data.scientificDegrees);
      // this.establishmentRoles.fillData(data.establishmentRoles);
    } else {
      this.setInitialValue();
    }
  }
}
