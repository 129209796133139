import { DEFAULT_PAGES } from "./../utils/constants";
import {
  USER_TYPE,
  SYSTEM_TYPE,
  EDUCATIONAL_RATING_TYPE_TOKENS,
} from "./../utils/constantLists";
import { checkPrivilege } from "./../utils/functions";
import i18n from "./../i18n";
import store from "./../store";
import * as privilegeHelper from "./../utils/privilegeHelper";

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.loggedIn });
  } else {
    next();
  }
};
export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.notLoggedIn });
  } else {
    next();
  }
};

export const handlePrivilege = (to, from, next, privilegeStatus) => {
  ifNotAuthenticated(to, from, next);
  if (privilegeStatus) {
    next();
  } else {
    next({ name: "Error404" });
  }
  if (store.getters.invalidLoginCodeStatus) {
    next({ name: "InvalidLoginClode" });
  } else {
    next();
  }
};

export const ifInvalidLoginClode = (to, from, next) => {
  ifNotAuthenticated(to, from, next);
  if (store.getters.invalidLoginCodeStatus) {
    next();
  } else {
    next({ name: "Error404" });
  }
};

const routes = [
  {
    path: "/invalid-login-clode",
    name: "InvalidLoginClode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodesUseCode.vue"),
    beforeEnter: ifInvalidLoginClode,

    meta: {
      // layout: "auth",
      pageName: "InvalidLoginClode",
      pageIcon: "homepage.svg",
    },
  },
  //#region General
  {
    path: "/test",
    name: "Test",
    component: () => import("./../views/general/Test.vue"),
    meta: {
      layout: "auth",
      pageName: "test",
      pageIcon: "homepage.svg",
    },
  },
  {
    path: "/test2",
    name: "TestSohagy",
    component: () => import("./../views/general/TestSohagy.vue"),
    meta: {
      layout: "auth",
      pageName: "test",
      pageIcon: "homepage.svg",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("./../views/home/Home.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "auth",
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
    },
  },
  //#endregion General

  //#region Auth
  {
    path: "/auth/login",
    name: "UserLogin",
    component: () => import("./../views/users/UserLogin"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "auth",
      pageName: i18n.t("login"),
      pageIcon: "homepage.svg",
    },
  },
  //#endregion Auth

  //#region UserAppSettings
  {
    path: "/user-app-settings",
    name: "UserAppSettings",
    component: () => import("./../views/userAppSettings/UserAppSettings.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("UserAppSettings.modelName"),
      pageIcon: "UserAppSettings.svg",
    },
  },
  //#endregion UserAppSettings

  //#region Educational
  {
    path: "/educational/educational-periods",
    name: "EducationalPeriods",
    component: () =>
      import(
        "./../views/educational/educationalPeriods/EducationalPeriods.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalPeriod())
      );
    },
    meta: {
      pageName: i18n.t("EducationalPeriods.modelName"),
      pageIcon: "educationalPeriods.svg",
    },
  },
  {
    path: "/educational/educational-categories/:parentEducationalCategoryToken?",
    name: "EducationalCategories",
    component: () =>
      import(
        "./../views/educational/educationalCategories/EducationalCategories.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalCategory())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("EducationalCategories.modelName"),
      pageIcon: "EducationalCategories.svg",
    },
  },
  {
    path: "/educational/system-components-educational-categories",
    name: "SystemComponentEducationalCategory",
    component: () =>
      import(
        "../views/systemConfiguration/systemComponents/SystemComponents.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasSystemComponentEducationalCategory())
      );
    },
    meta: {
      pageName: i18n.t("SystemComponents.modelName"),
      pageIcon: "systemComponents.svg",
      systemTypeToken: SYSTEM_TYPE.EducationalActivity,
      modelName: "systemComponentEducationalCategory",
    },
    props: true,
  },
  {
    path: "/educational/system-components-hierarchies-educational-categories",
    name: "SystemComponentsHierarchyEducationalCategory",
    component: () =>
      import(
        "../views/systemConfiguration/systemComponentsHierarchies/SystemComponentsHierarchies.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          privilegeHelper.hasSystemComponentsHierarchyEducationalCategory()
        )
      );
    },
    meta: {
      pageName: i18n.t("SystemComponentsHierarchies.modelName"),
      pageIcon: "systemComponentsHierarchies.svg",
      systemTypeToken: SYSTEM_TYPE.EducationalActivity,
      modelName: "systemComponentsHierarchyEducationalCategory",
    },
    props: true,
  },
  {
    path: "/educational/educational-ratings/educational-categories-ratings/:educationalCategoryToken?",
    name: "EducationalCategoriesRatings",
    component: () =>
      import(
        "./../views/educational/educationalRatings/EducationalRatings.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          privilegeHelper.hasEducationalCategory() &&
            privilegeHelper.hasEducationalRating()
        )
      );
    },
    props: true,
    meta: {
      educationalRatingTypeToken:
        EDUCATIONAL_RATING_TYPE_TOKENS.EducationalCategory,
      pageName: i18n.t("EducationalRatings.categoriesRatings"),
      pageIcon: "educationalRatings.svg",
    },
  },

  {
    path: "/educational/educational-groups/:educationalCategoryToken?",
    name: "EducationalGroups",
    component: () =>
      import("./../views/educational/educationalGroups/EducationalGroups.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalGroup())
      );
    },
    meta: {
      pageName: i18n.t("EducationalGroups.modelName"),
      pageIcon: "EducationalGroups.svg",
    },
    props: true,
  },
  {
    path: "/educational/educational-ratings/educational-groups-ratings/:educationalGroupToken?",
    name: "EducationalGroupsRatings",
    component: () =>
      import(
        "./../views/educational/educationalRatings/EducationalRatings.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          privilegeHelper.hasEducationalGroup() &&
            privilegeHelper.hasEducationalRating()
        )
      );
    },
    props: true,
    meta: {
      educationalRatingTypeToken:
        EDUCATIONAL_RATING_TYPE_TOKENS.EducationalGroup,
      pageName: i18n.t("EducationalRatings.groupsRatings"),
      pageIcon: "educationalRatings.svg",
    },
  },

  {
    path: "/educational/educational-schedule-times/:educationalGroupToken?",
    name: "EducationalScheduleTimes",
    component: () =>
      import(
        "./../views/educational/educationalScheduleTimes/EducationalScheduleTimes.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalScheduleTime())
      );
    },
    meta: {
      pageName: i18n.t("EducationalScheduleTimes.modelName"),
      pageIcon: "EducationalScheduleTimes.svg",
    },
    props: true,
  },
  {
    path: "/educational/educational-schedule-times/details/:educationalScheduleTimeToken?",
    name: "EducationalScheduleTimeDetails",
    component: () =>
      import(
        "./../views/educational/educationalScheduleTimes/EducationalScheduleTimeDetails.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalScheduleTime())
      );
    },
    meta: {
      pageName: i18n.t("EducationalScheduleTimeDetails.modelName"),
      pageIcon: "EducationalScheduleTimeDetails.svg",
    },
    props: true,
  },

  {
    path: "/educational/educational-group-students/:educationalGroupToken?/:educationalCategoryToken?",
    name: "EducationalGroupStudents",
    component: () =>
      import(
        "./../views/educational/educationalGroupStudents/EducationalGroupStudents.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalGroupStudent())
      );
    },
    meta: {
      pageName: i18n.t("EducationalGroupStudents.modelName"),
      pageIcon: "EducationalGroupStudents.svg",
    },
    props: true,
  },
  {
    path: "/educational/educational-group-transfers",
    name: "EducationalGroupTransfers",
    component: () =>
      import(
        "./../views/educational/educationalGroupTransfers/EducationalGroupTransfers.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalGroupTransfer())
      );
    },
    meta: {
      pageName: i18n.t("EducationalGroupTransfers.modelName"),
      pageIcon: "EducationalGroupTransfers.svg",
    },
    props: true,
  },
  {
    path: "/educational/educational-group-attendances/:educationalGroupToken?",
    name: "EducationalGroupAttendances",
    component: () =>
      import(
        "./../views/educational/educationalGroupAttendances/EducationalGroupAttendances.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalGroupAttendance())
      );
    },
    meta: {
      pageName: i18n.t("EducationalGroupAttendances.modelName"),
      pageIcon: "EducationalGroupAttendances.svg",
    },
    props: true,
  },
  {
    path: "/educational/educational-ratings/educational-schedule-times-ratings/:educationalScheduleTimeToken?",
    name: "EducationalScheduleTimesRatings",
    component: () =>
      import(
        "./../views/educational/educationalRatings/EducationalRatings.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          privilegeHelper.hasEducationalScheduleTime() &&
            privilegeHelper.hasEducationalRating()
        )
      );
    },
    props: true,
    meta: {
      educationalRatingTypeToken:
        EDUCATIONAL_RATING_TYPE_TOKENS.EducationalScheduleTime,
      pageName: i18n.t("EducationalRatings.scheduleTimesRatings"),
      pageIcon: "educationalRatings.svg",
    },
  },
  {
    path: "/educational/simple-exam-models/:simpleExamModelToken?",
    name: "SimpleExamModels",
    component: () =>
      import("./../views/educational/simpleExamModels/SimpleExamModels.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasSimpleExamModel())
      );
    },
    meta: {
      pageName: i18n.t("SimpleExamModels.modelName"),
      pageIcon: "SimpleExamModels.svg",
    },
    props: true,
  },

  {
    path: "/educational/group-schedule-exams/:educationalGroupToken?",
    name: "GroupScheduleExams",
    component: () =>
      import(
        "./../views/educational/groupScheduleExams/GroupScheduleExams.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasGroupScheduleExam())
      );
    },
    meta: {
      pageName: i18n.t("GroupScheduleExams.modelName"),
      pageIcon: "GroupScheduleExams.svg",
    },
    props: true,
  },
  {
    path: "/educational/student-schedule-exam-times/:educationalGroupToken?/:userToken?",
    name: "StudentScheduleExamTimes",
    component: () =>
      import(
        "./../views/educational/studentScheduleExamTimes/StudentScheduleExamTimes.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasStudentScheduleExamTime())
      );
    },
    meta: {
      pageName: i18n.t("StudentScheduleExamTimes.modelName"),
      pageIcon: "StudentScheduleExamTimes.svg",
    },
    props: true,
  },
  //#endregion Educational

  //#region JoiningApplications
  {
    path: "/JoiningApplications/educational-joining-application/:userStudentToken?/:educationalPeriodToken?/:educationalCategoryToken?",
    name: "EducationalJoiningApplications",
    props: true,
    component: () =>
      import(
        "./../views/educational/educationalJoiningApplications/EducationalJoiningApplications.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalJoiningApplication())
      );
    },
    meta: {
      pageName: i18n.t("EducationalJoiningApplications.modelName"),
      pageIcon: "EducationalJoiningApplications.svg",
    },
  },
  //#endregion JoiningApplications

  //#region Users
  {
    path: "/users/admins-egypt/:userToken?",
    name: "AdminsEgypt",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAdminsEgypt())
      );
    },
    meta: {
      pageName: i18n.t("adminsEgypt.modelName"),
      pageIcon: "adminsEgypt.svg",
      userTypeToken: USER_TYPE.AdminsEgypt,
      modelName: "adminsEgypt",
      StorageSpacesRoute: "StorageSpacesAdminsEgypt",
    },
    props: true,
  },
  {
    path: "/users/master-admin/:userToken?",
    name: "MasterAdmin",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasMasterAdmin())
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      userTypeToken: USER_TYPE.MasterAdmin,
      modelName: "masterAdmins",
      StorageSpacesRoute: "StorageSpacesMasterAdmin",
    },
    props: true,
  },
  {
    path: "/users/employees/:userToken?",
    name: "Employees",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEmployee())
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      userTypeToken: USER_TYPE.Employee,
      modelName: "employees",
      StorageSpacesRoute: "StorageSpacesEmployee",
    },
    props: true,
  },
  {
    path: "/users/students/:userToken?",
    name: "Students",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasStudent())
      );
    },
    meta: {
      pageName: i18n.t("students.modelName"),
      pageIcon: "students.svg",
      userTypeToken: USER_TYPE.Student,
      modelName: "students",
      StorageSpacesRoute: "StorageSpacesStudent",
    },
    props: true,
  },
  //#endregion Users

  //#region UsersPersonalData
  {
    path: "/users/user-family/:userTypeToken/:userToken?",
    name: "UserFamilies",
    component: () =>
      import("./../views/usersPersonalData/userFamilies/UserFamilies.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("UserFamilies.modelName"),
      pageIcon: "UserFamilies.svg",
    },
    props: true,
  },
  {
    path: "/users/user-qualifications/:userTypeToken/:userToken?",
    name: "UserQualifications",
    component: () =>
      import(
        "./../views/usersPersonalData/userQualifications/UserQualifications.vue"
      ),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("UserQualifications.modelName"),
      pageIcon: "UserQualifications.svg",
    },
    props: true,
  },

  //#endregion UsersPersonalData

  //#region Services
  {
    path: "/settings/services-and-counted-services/services",
    name: "Services",
    component: () => import("./../views/services/Services.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasService())
      );
    },
    meta: {
      pageName: i18n.t("Services.modelName"),
      pageIcon: "services.svg",
    },
  },
  //#endregion Services

  //#region CountedServices
  {
    path: "/settings/services-and-counted-services/countedServices",
    name: "CountedServices",
    component: () => import("./../views/countedServices/CountedServices.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasCountedService())
      );
    },
    meta: {
      pageName: i18n.t("CountedServices.modelName"),
      pageIcon: "countedServices.svg",
    },
  },
  //#endregion CountedServices
  //#region Newss
  {
    path: "/news",
    name: "Newss",
    component: () => import("./../views/newss/Newss.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasNews())
      );
    },
    meta: {
      pageName: i18n.t("Newss.modelName"),
      pageIcon: "news.svg",
    },
  },
  //#endregion Services

  //#region Finance
  //#region Accounts
  {
    path: "/finance/accounts",
    name: "Accounts",
    component: () => import("./../views/finance/accounts/Accounts.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAccount())
      );
    },
    meta: {
      pageName: i18n.t("Accounts.modelName"),
      pageIcon: "accounts.svg",
    },
  },
  //#endregion Accounts

  //#region AccountTransactions
  {
    path: "/finance/accountTransactions",
    name: "AccountTransactions",
    component: () =>
      import("./../views/finance/accountTransactions/AccountTransactions.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAccountTransaction())
      );
    },
    meta: {
      pageName: i18n.t("AccountTransactions.modelName"),
      pageIcon: "accountTransactions.svg",
    },
  },
  //#endregion AccountTransactions

  //#region CountedServiceTransactions
  {
    path: "/finance/counted-service-transactions",
    name: "CountedServiceTransactions",
    component: () =>
      import(
        "./../views/finance/countedServiceTransactions/CountedServiceTransactions.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasCountedServiceTransaction())
      );
    },
    meta: {
      pageName: i18n.t("CountedServiceTransactions.modelName"),
      pageIcon: "countedServiceTransactions.svg",
    },
  },
  //#endregion CountedServiceTransactions

  //#region Expenses
  {
    path: "/finance/expenses",
    name: "Expenses",
    component: () => import("./../views/finance/expenses/Expenses.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasExpense())
      );
    },
    meta: {
      pageName: i18n.t("Expenses.modelName"),
      pageIcon: "expenses.svg",
    },
  },
  //#endregion Expenses

  //#region Revenues
  {
    path: "/finance/revenues",
    name: "Revenues",
    component: () => import("./../views/finance/revenues/Revenues.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasRevenue())
      );
    },
    meta: {
      pageName: i18n.t("Revenues.modelName"),
      pageIcon: "revenues.svg",
    },
  },
  //#endregion Revenues

  {
    path: "/JoiningApplications/Debt/:userToken?",
    name: "Debts",
    component: () => import("./../views/joiningApplications/debts/Debts.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasDebt())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("Debts.modelName"),
      pageIcon: "debts.svg",
    },
  },
  {
    path: "/JoiningApplications/Installments/:userToken?",
    name: "Installments",
    component: () =>
      import("./../views/joiningApplications/installments/Installments.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasInstallment())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("Installments.modelName"),
      pageIcon: "installments.svg",
    },
  },
  {
    path: "/JoiningApplications/InstallmentPayments/:userToken?",
    name: "InstallmentPayments",
    component: () =>
      import(
        "./../views/joiningApplications/installmentPayments/InstallmentPayments.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasInstallmentPayment())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("InstallmentPayments.modelName"),
      pageIcon: "installmentPayments.svg",
    },
  },
  {
    path: "/JoiningApplications/ServiceEntitlements",
    name: "ServiceEntitlements",
    component: () =>
      import(
        "./../views/joiningApplications/serviceEntitlements/ServiceEntitlements.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasServiceEntitlement())
      );
    },
    meta: {
      pageName: i18n.t("ServiceEntitlements.modelName"),
      pageIcon: "serviceEntitlements.svg",
    },
  },
  //#endregion Finance

  //#region Alerts
  //#region Complaints
  {
    path: "/alerts/complaints",
    name: "Complaints",
    component: () => import("./../views/complaints/complaints/Complaints.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasComplaint())
      );
    },
    meta: {
      pageName: i18n.t("Complaints.modelName"),
      pageIcon: "complaints.svg",
    },
  },
  //#endregion Complaints

  //#region Reminders
  {
    path: "/alerts/reminders",
    name: "Reminders",
    component: () => import("./../views/reminders/Reminders.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasReminder())
      );
    },
    meta: {
      pageName: i18n.t("Reminders.modelName"),
      pageIcon: "reminders.svg",
    },
  },
  //#endregion Reminders

  //#endregion Alerts

  //#region InterestsAndFollowUps
  //#region Interests
  {
    path: "/interests",
    name: "Interests",
    component: () => import("./../views/followUps/interests/Interests.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasInterest())
      );
    },
    meta: {
      pageName: i18n.t("Interests.modelName"),
      pageIcon: "interests.svg",
    },
  },
  //#endregion Interests
  //#region InterestFollowUps
  {
    path: "/interest-follow-ups/:interestToken?/:mainToken?/:followUpTypeToken?",
    name: "InterestFollowUps",
    component: () =>
      import("./../views/followUps/interestFollowUps/InterestFollowUps.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasInterestFollowUp())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("InterestFollowUps.modelName"),
      pageIcon: "interestFollowUps.svg",
    },
  },
  //#endregion InterestFollowUps

  //#endregion InterestsAndFollowUps

  //#region Error
  {
    path: "/error-404",
    name: "Error404",
    component: () => import("./../views/errors/Error-404.vue"),
    meta: {
      pageName: i18n.t("pageNotFound"),
      pageIcon: "404-icon.svg",
      currentPage: "",
      currentSubPage: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Error404" },
  },
  //#endregion Error
];

export default routes;
